import { star, house, santa, gift, particle } from './sprites'
import { distance, pointInRect } from './util'
import santaImage from '../assets/sled.png'
import giftImage from '../assets/gift.png'

const audioUrl = new URL('../assets/music.mp3', import.meta.url);
let sound = new Audio(audioUrl);

// rotera skärm
// screen.orientation.addEventListener('change', (e) => {
//   location.reload()
// })

// fulhack 3232 - medelande
const scoreElement = document.getElementById("score")
const messageElement = document.getElementById("message")
const messages = ["GOD JUL", "GOTT NYTT ÅR", "ÖNSKAR", "ALMA ERNA\nKARRO NILS"]
let messageIndex = 0
window.setInterval(() => {
  messageElement.style.marginTop = "20px"
  messageElement.style.opacity = "0.2"
  window.setTimeout(() => {
    messageElement.style.opacity = "0"
    messageElement.style.marginTop = "0px"
  },4000)

  messageElement.innerText = messages[messageIndex]
  
  messageIndex++
  if(messageIndex > messages.length-1) {messageIndex = 0}
},5000)


const screenWidth = 800
const screenHeight = 400

const game = {
  started: false,
  ended: false,
  press: false,
  gravity: 0.01,
  boost: 0.05,
  maxdy: 3,
  startpos: screenWidth / 2,
  speed: 1,
  score:0
}

const loop = {
  currentFrame: 0,
  currentTime:0,
  timeScale:1,
}

// init background
const bgcanvas = document.getElementById('bgcanvas')
const bgctx = bgcanvas.getContext('2d')
bgctx.canvas.width = screenWidth
bgctx.canvas.height = screenHeight
for (let i = 0; i < 100; i++) {
  star(
    bgctx,
    Math.random() * bgctx.canvas.width,
    (Math.random() * bgctx.canvas.height) / 1.5,
  )
}

// init foreground
const canvas = document.getElementById('canvas')
const fgctx = canvas.getContext('2d')
fgctx.canvas.width = screenWidth
fgctx.canvas.height = screenHeight

// setup santa
var img = new Image()
img.src = santaImage
santa.img = img
const setStartPos = () => {
  santa.x = -16
  santa.y = 32
  santa.dy = 0
}
setStartPos()

// particles
let activeParticles = 0
const particleLife = 400
const particlePool = []
for (let i = 0; i < 100; i++) {
  const particleClone = { ...particle }
  particleClone.dx = -Math.random() * 1 - 1
  particleClone.dy = Math.random() / 2 - 0.25
  particlePool.push(particleClone)
}

// generate houses
const houses = []
const houseColors = ['#130A19', '#0A0D19', '#0A1519', '#000528']
for (let i = 0; i < 12; i++) {
  const houseClone = { ...house }
  houseClone.color = houseColors[Math.floor(Math.random() * houseColors.length)]
  houseClone.x = i * 80
  houseClone.y = Math.cos(i) * 96 + 256
  houseClone.generate()
  houses.push(houseClone)
}

// generate gifts
const gifts = []
var giftImg = new Image()
giftImg.src = giftImage
for (let i = 0; i < 12; i++) {
  const giftClone = { ...gift }
  giftClone.x = i * 80 + 50
  giftClone.y = Math.cos(i) * 96 + 196
  giftClone.img = giftImg
  gifts.push(giftClone)
}

// controls
document.addEventListener('mousedown', () => {
  game.press = true
  sound.play()
})

document.addEventListener('mouseup', () => {
  game.press = false
})

document.addEventListener('touchstart', () => {
  game.press = true
})

document.addEventListener('touchend', () => {
  game.press = false
})

window.requestAnimationFrame(animationFrameCallback)

function animationFrameCallback(time) {
  //window.requestAnimationFrame(animationFrameCallback)


  if (game.press) {
    game.started = true
  }



  loop.currentFrame++
  loop.timeScale = (time - loop.currentTime) / 10
  loop.currentTime = time

  update()
  draw()
 // setTimeout(() => {
    window.requestAnimationFrame(animationFrameCallback)
 // }, 1000 / 120);

  //console.log(loop.timeScale)
}

const update = () => {
  santa.y += santa.dy
  if (santa.x < game.startpos - 10) {
    santa.x += (game.startpos - santa.x) / 100
  }

  for (let i = 0; i < houses.length; i++) {
    if (houses[i].x < -80) {
      houses[i].x = 878
    }

    houses[i].x -= game.speed * loop.timeScale

    // hit house
    if (
      pointInRect(
        santa.x + 8,
        santa.y + 8,
        houses[i].x,
        houses[i].y,
        houses[i].x + 80,
        houses[i].y + 200,
      )
    ) {
      santa.x = houses[i].x
      game.ended = true
      game.score = 0
      reset()
    }
  }

  if (activeParticles < particlePool.length && loop.currentFrame % 10 == 0) {
    activeParticles++
  }

  for (let i = 0; i < activeParticles; i++) {
    particlePool[i].x += particlePool[i].dx * loop.timeScale
    particlePool[i].y += particlePool[i].dy * loop.timeScale + game.gravity * loop.timeScale
    particlePool[i].frame++

    if (particlePool[i].frame > particleLife) {
      particlePool[i].x = santa.x - 10
      particlePool[i].y = santa.y
      particlePool[i].frame = 0
      particlePool[i].show = true
    }
  }

  for (let i = 0; i < gifts.length; i++) {
    if (gifts[i].x < -80) {
      gifts[i].x = 878
      gifts[i].render = true
    }

    gifts[i].x -= game.speed * loop.timeScale
    gifts[i].y = gifts[i].y + Math.cos(loop.currentFrame / 100) / 4

    // take gift?
    if (gifts[i].render && distance(santa.x + 8, santa.y + 8, gifts[i].x, gifts[i].y) < 20) {
      gifts[i].render = false
      game.score++
      scoreElement.innerText = game.score + " POÄNG"
    }
  }

  if (!game.started) {
    return
  }

  if (game.press && santa.dy > -game.maxdy) {
    santa.dy += -game.boost * loop.timeScale
  } else if (santa.dy < game.maxdy) {
    santa.dy += game.gravity * loop.timeScale
  }
}

const draw = () => {
  fgctx.clearRect(0, 0, canvas.width, canvas.height)

  for (let i = 0; i < houses.length; i++) {
    houses[i].draw(fgctx)
  }

  for (let i = 0; i < gifts.length; i++) {
    gifts[i].draw(fgctx)
  }

  for (let i = 0; i < particlePool.length; i++) {
    particlePool[i].draw(fgctx)
  }

  santa.draw(fgctx, img)
}

const reset = () => {
  for (let i = 0; i < gifts.length; i++) {
    gifts[i].render = true
  }
  game.started = false
  setStartPos()
}
