const star = (ctx, x, y) => {
  ctx.fillStyle = '#ffffff'
  ctx.fillRect(x, y, 1, 1)
}

const yellows = ['#E8F320','#D3DE06','#FDAC15','#FD5315']
const santaParticle =  {
  x: 0,
  y: 0,
  dy:0,
  height:10,
  draw: function(ctx) {

    ctx.translate(this.x, this.y)
    ctx.rotate(0.0174533 * this.dy * 20)
    ctx.fillStyle = yellows[Math.floor(Math.random()*yellows.length)]
    ctx.fillRect(0,-this.height/2 - this.dy * 5, 8, this.height)
    ctx.rotate(-0.0174533 * this.dy * 20)
    ctx.translate(-this.x, -this.y)
  }
}

const santa = {
  x: 0,
  y: 0,
  dy: 0,
  img: undefined,
  draw: function (ctx) {
    ctx.translate(this.x, this.y)
    ctx.rotate(0.0174533 * this.dy * 20)
    ctx.drawImage(this.img, -16, -16)
    ctx.rotate(-0.0174533 * this.dy * 20)
    ctx.translate(-this.x, -this.y)
  },
}

const gift = {
  x: 0,
  y: 0,
  dy: 0,
  render: true,
  img: undefined,
  draw: function (ctx) {

    if(!this.render) return

    ctx.translate(this.x, this.y)
    ctx.rotate(0.0174533 * this.dy * 20)
    ctx.drawImage(this.img, -16, -16)
    ctx.rotate(-0.0174533 * this.dy * 20)
    ctx.translate(-this.x, -this.y)
  },
}

const house = {
  x: 0,
  y: 0,
  color: '#130A19',
  bitmap: undefined,
  draw: function (ctx) {
    ctx.putImageData(this.bitmap, this.x, this.y)
  },
  generate: function () {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    ctx.canvas.width = 1060
    ctx.canvas.height = 400

    ctx.fillStyle = this.color
    ctx.fillRect(this.x, this.y, 80, 320)

    // windows
    ctx.fillStyle = 'gray'
    for (let y = 0; y < 20; y++) {
      for (let x = 0; x < 5; x++) {
        ctx.fillRect(
          this.x + 10 + x * 10 + x * 3,
          this.y + 10 + y * 10 + y * 5,
          8,
          10,
        )
      }
    }

    // lights
    ctx.fillStyle = 'yellow'
    for (let i = 0; i < 10; i++) {
      const x = Math.floor(Math.random() * 5)
      const y = Math.floor(Math.random() * 20)

      ctx.fillRect(this.x + 10 + x * 13, this.y + 10 + y * 15, 8, 10)
    }

    this.bitmap = ctx.getImageData(this.x, this.y, 80, 320)
    ctx.clearRect(0, 0, canvas.width, canvas.height)
  },
}

const particle = {
  x:0,
  y:0,
  dx:0,
  dy:0,
  frame: 0,
  show:false,
  draw: function(ctx) {
    this.frame++
    ctx.fillStyle = '#ffffff'
    ctx.fillRect(this.x, this.y, 3, 3)
  }
}


export { star, house, santa, gift, santaParticle, particle }
